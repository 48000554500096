import React from "react";
import { Link } from "react-router-dom";
import { RouteNames } from "../router";

const Offer = () => {
  return (
    <div className={"m-10 text-sm"}>
      <Link to={RouteNames.MAIN}>ВЕРНУТЬСЯ ОБРАТНО </Link>
      <h1>
        Договор публичной оферты на предоставление прав доступа к информационным
        материалам
      </h1>
      <p>
        Настоящий Договор является официальным предложением (публичной офертой)
        сайта k-store.ru (в дальнейшем «Исполнитель»), для любого лица (далее –
        «Заказчик»), которое примет настоящее предложение, на указанных ниже
        условиях, по предоставлению прав доступа к информационным материалам,
        расположенным на сайте ku-store.ru Исполнителя, а также оказания
        сопутствующих информационных услуг на возмездной основе. В соответствии
        с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (ГК
        РФ), в случае принятия изложенных ниже условий и оплаты Услуг
        юридическое или физическое лицо, производящее Акцепт этой Оферты,
        становится Заказчиком (в соответствии с пунктом 3 статьи 438 ГК РФ
        Акцепт Оферты равносилен заключению Договора на условиях, изложенных в
        Оферте). Моментом полного и безоговорочного принятия Заказчиком
        предложения Исполнителя заключить договор оферты (акцептом оферты)
        считается факт заполнения регистрации аккаунта на сайте ku-store.ru с
        обязательным указанием действующего адреса электронной почты для
        направления Исполнителем во исполнение требований N 54-ФЗ "О применении
        контрольно-кассовой техники при осуществлении расчетов в Российской
        Федерации" электронного кассового чека и оплаты Заказчиком услуг
        Исполнителя по предоставлению доступа согласно выбранной подписке.
        Осуществляя оплату услуг, Заказчик гарантирует, что уже ознакомлен и
        принимает все условия Оферты в том виде, в каком они изложены в тексте
        настоящей Оферты, а также ознакомлен и согласен со стоимостью Услуг
        (подпиской), указанной на сайте Исполнителя. Текст настоящего Договора
        оферты (далее по тексту – «Договор», «Оферта») расположен по адресу:
        https://ku-store.ru/offer (далее – Сайт Исполнителя). Если Вы не
        согласны с настоящим Договором оферты (любым из условий Договора),
        просим Вас не совершать действий, направленных на его заключение.
      </p>
      <h2>1. Термины и определения</h2>
      <p>
        1.1. Для целей настоящего Договора приведенные ниже термины и
        определения используются в следующем значении: «Оферта» — настоящий
        документ, Публичная Оферта продажи прав доступа к информационным
        материалам Исполнителя, размещенным на Сайте, определяющее права и
        обязанности сторон, порядок и условия оказания Услуг. Понятия «Оферта» и
        «Договор», употребляемые в тексте настоящей Оферты, равнозначны. «Акцепт
        Оферты» — полное и безоговорочное принятие Заказчиком условий настоящей
        Оферты, методами, предусмотренными в п. 3, данной Оферты. «Заказчик» —
        любое дееспособное лицо, изъявившее желание получить услуги по
        предоставлению доступа к информационным материалам от Исполнителя, а
        также информационных услуг и акцептовавшее Оферту на нижеописанных
        условиях. «Исполнитель» — зарегистрированный в соответствии с
        действующим законодательством Индивидуальный предприниматель, являющийся
        полноправным владельцем информационных материалов, размещенных на Сайте,
        оказывающий услуги по предоставлению доступа к информационным материалам
        Сайте посредством сети Интернет, а также с использованием Сайта.
        «Услуги», «Услуга» — доведение информационных материалов, размещенных на
        Сайте, до Заказчика, посредством информационно-телекоммуникационной сети
        «Интернет» путем предоставления Заказчику доступа на основании оплаты
        выбранного тарифа, а также оказания дополнительных информационных услуг.
        «Сайт» — совокупность связанных между собой веб-страниц, объединенных
        под одним доменным именем или ip- адресу, представляющий собой
        организованную совокупность связанных между собой программ для ЭВМ,
        текстов, графических изображений, фото, видеоматериалов, иных объектов
        интеллектуальных прав, принадлежащих Исполнителю, расположенный по
        адресу https://ku-store.ru/ «Информационные материалы» — любые видео-,
        аудио-, текстовые и иные материалы, размещенные на Сайте, организацию
        доступа к которым предоставляет Исполнитель. «Личный кабинет» —
        совокупность защищенных страниц на Сайте Исполнителя, создаваемых при
        регистрации Заказчика, посредством которого осуществляется
        взаимодействие Сторон и получение доступа к информационным материалам.
        Доступ к Личному кабинету осуществляется Заказчиком посредством ввода
        электронной почты и пароля. «Программное обеспечение (ПО)» — браузер
        (FireFox, GoogleChrome и аналогичные) для доступа к информационным
        ресурсам, находящимся в сети Интернет, иные программы для обработки
        предоставляемой Исполнителем информации. «Электронная почта» –
        индивидуальный адрес Заказчика, используемое им на Сайте Исполнителя.
        «Пароль» – сочетание букв, цифр и символов, уникальное для Заказчика,
        позволяющее при одновременном вводе с Почтой войти в личный кабинет и
        получить доступ к информационным материалам. «Защищенные страницы Сайта»
        – страницы Сайта, доступ к которым возможен только при вводе Заказчиком
        Почты и Пароля. «Подписка»/ «Пакетное предложение» – конкретное
        предложение, которое содержит исчерпывающий перечень условий заключения
        Договора: период доступа Заказчика к информационным материалам, состав
        информационных материалов, к которым предоставляется доступ, стоимость
        предоставления доступа, иные существенные условия. Описание доступных
        тарифов представлено на Сайте.
      </p>
      <p>
        1.2. В настоящей Оферте могут быть использованы термины, не определенные
        в п.1.1. В этом случае толкование такого термина производится в
        соответствии с текстом настоящей Оферты. В случае отсутствия
        однозначного толкования термина в тексте Оферты следует
        руководствоваться толкованием термина: в первую очередь – на сайте
        Исполнителя, во вторую очередь – общепринятым значением.
      </p>
      <h2>2. Предмет Договора Оферты</h2>
      <p>
        2.1. По настоящему Договору Исполнитель обязуется обеспечить Заказчику
        на возмездной основе предоставление доступа к информационным материалам,
        а также оказать информационную поддержку на условиях выбранной подписке,
        а Заказчик – принять и оплатить такой доступ.
      </p>
      <p>
        2.2. Информация и иные материалы, в том числе, аудио и видеозаписи,
        предоставленные Заказчику Исполнителем в рамках оказания услуг по
        настоящему Договору, предоставляются исключительно для личного
        использования Заказчиком на условиях простой неисключительной лицензии
        (пп. 1 п. 1 ст. 1236 ГК РФ). Оплата за предоставление простой
        (неисключительной) лицензии включена в цену предоставления доступа. В
        случае, если Заказчик допустит распространение указанной информации, он
        несет ответственность перед Исполнителем за причиненные фактом
        распространения информации убытки в виде штрафа, размер которого указан
        в п. 8.2., а также на него может распространяться гражданская,
        административная или уголовная ответственность согласно действующему
        законодательству в сфере интеллектуальной собственности. Заказчик
        признает, что все комментарии, оставленные им или другими участниками на
        Сайте Исполнителя, становятся частью материалов Исполнителя, и к ним
        применяются все положения по охране интеллектуальной собственности,
        изложенные в настоящем Договоре оферты, включая ответственность за
        нарушение п. 6.3.3 Договора.
      </p>
      <p>
        2.3. Исполнитель имеет право изменять объем оказываемых услуг,
        стоимость, условия данной Публичной Оферты без предварительного
        согласования с Заказчиком, обеспечивая при этом публикацию измененных
        условий на сайте Исполнителя, а также в общедоступном для ознакомления с
        этими документами месте, не менее чем за 1 (один) рабочий день до их
        ввода в действие. Продолжая использование Сайта после вступления в силу
        соответствующих изменений, Заказчик выражает свое согласие с новыми
        условиями.
      </p>
      <p>
        2.4. Настоящий Договор имеет силу акта об оказании Услуг. Приемка
        производится без подписания соответствующего акта.
      </p>
      <h2>3. Акцепт Оферты и заключение Договора Оферты</h2>
      <p>
        3.1. Заказчик производит Акцепт Оферты путем заполнения формы (заявки) с
        указанием своих персональных данных, в том числе и адреса действующей
        электронной почты, и внесения предоплаты услуг Исполнителя по
        предоставлению доступа на условиях выбранной подписки, если иное не
        содержится на Сайте Исполнителя.
      </p>
      <p>
        3.2. Осуществляя акцепт настоящей Оферты, Заказчик подтверждает, что
        предоставление права доступа к информационным материалам по настоящему
        Договору полностью соответствует возможности Заказчика пользоваться
        Услугами, оказываемыми таким способом. Если Заказчик не согласен с
        каким-либо условием Договора, Исполнитель предлагает отказаться от
        акцепта и заключить персональный договор на отдельно обсуждаемых с
        Исполнителем условиях.
      </p>
      <p>
        3.3. Заказчик обязуется самостоятельно обеспечить наличие программного
        обеспечения (браузера, антивирусных программ) на своем персональном
        компьютере или иных устройствах, необходимых для получения доступа.
        Исполнитель не отвечает за не предоставление Услуг в связи с
        невозможностью Заказчика их получить по причине отсутствия подключения к
        сети Интернет и необходимого компьютерного/программного обеспечения, в
        том числе несоответствия характеристик персонального компьютера и иных
        используемых устройств минимальным требованиям для работы Сайта и
        предоставления доступа.
      </p>
      <p>
        3.4. Передача Исполнителем Заказчику реквизитов доступа к информационным
        материалам – является надлежащим исполнением настоящего Договора со
        стороны Исполнителя.
      </p>
      <p>
        3.5. Продолжительность периода доступа Заказчика к информационным
        материалам зависит от выбранной подписи. Сроки предоставления доступа к
        информационным материалам указаны в описании Подписок на Сайте
        Исполнителя.
      </p>
      <p>
        3.6. Никакая информация, материалы и/или консультации, предоставляемые
        Исполнителем в рамках оказания услуг по настоящему Договору, не могут
        рассматриваться как гарантии. Принятие решений на основе всей
        предоставленной Исполнителем информации находится в исключительной
        компетенции Заказчика. Заказчик принимает на себя полную ответственность
        и риски, связанные с использованием информации и материалов,
        предоставленных Исполнителем в рамках исполнения своих обязательств по
        настоящему Договору.
      </p>
      <h2>4. Стоимость услуг по предоставлению доступа и порядок расчетов.</h2>
      <p>
        4.1. Стоимость предоставления доступа составляет 5000 (пять тысяч)
        рублей 00 копеек за 30 дней и 12 000 (двенадцать тысяч) 00 копеек рублей
        за 90 дней. Оплата возможна картой или по расчётному счету. Реквизиты
        карты Исполнитель не сохраняет на своей стороне, они вводятся и хранятся
        на стороне интернет-эквайринга «Банк Точка», который обеспечивает
        надлежащую безопасность при хранении платежных данных.
      </p>
      <p>
        4.2. Оплата Услуг по предоставлению доступа производится Заказчиком в
        порядке предварительной оплаты в размере 100% суммы Услуг.
      </p>
      <p>
        4.3. Обязательства по оплате следует считать исполненными со дня
        получения Исполнителем суммы оплаты в полном размере.
      </p>
      <p>
        4.4. Заказчик самостоятельно отслеживает изменение реквизитов
        Исполнителя, указанных в настоящей Оферте, и несет ответственность за
        правильность производимых им платежей.
      </p>
      <p>4.5. Все расчеты по Договору производятся в рублях.</p>
      <h2>5. Возврат оплаты по Договору</h2>
      <p>
        5.1. Возврат суммы оплаты доступа к информационным материалам
        производится в случае, если Заказчик до предоставления ему доступа
        направил Исполнителю заявление о возврате суммы оплаты.
      </p>
      <p>
        5.2. Отказ Заказчика от Договора производится по заявлению Заказчика,
        сделанному в письменной форме и направленному на электронную почту
        Исполнителя.
      </p>
      <p>
        5.3. Не является основанием для возврата то обстоятельство, что
        программное обеспечение на персональном компьютере, ноутбуке Заказчика и
        его технические средства не позволяют просматривать и изучать материалы
        Сайта.
      </p>
      <p>
        5.4. Решение о возврате или об отказе в возврате денежных средств
        принимается Исполнителем в течение 10 (десяти) дней с момента получения
        Исполнителем письменного заявления Заказчика о возврате.
      </p>
      <p>
        5.5. Денежные средства возвращаются на тот счет Заказчика, с которого
        производилась оплата, либо на иной счет по согласованию Сторон. При этом
        Заказчик уведомлен о том, что Исполнитель не возвращает уплаченные
        Заказчиком комиссии банкам или иным кредитным организациям, т.к. эти
        расходы являются расходами Заказчика, которые он несет самостоятельно.
      </p>
      <h2>6. Права и обязанности сторон</h2>
      <p>
        6.1. Исполнитель обязуется: 6.1.1. Обеспечивать исполнение Услуг в
        надлежащем качестве, в согласованный сторонами срок. 6.1.2.
        Предоставлять Заказчику: доступ к программному обеспечению, позволяющему
        Заказчику самостоятельно изучать информационные материалы в виде
        видеозаписей и презентаций в электронном виде на условиях настоящего
        Договора;
      </p>
      <p>
        6.2. Исполнитель имеет право: 6.2.1. Изменять объем оказываемых услуг,
        стоимость, условия данной публичной Оферты без предварительного
        согласования с Заказчиком. 6.2.2. Добавлять электронный адрес Заказчика,
        указанный при регистрации, в свой список рассылки писем для
        информирования Заказчика о поступлении новых видеоуроков Исполнителя.
        6.2.3. Приостановить оказание Услуг по настоящему Договору в случае
        получения через службу поддержки уведомления Заказчика о направлении
        заявления о возврате. 6.2.4. Привлекать третьих лиц для оказания Услуг
        по Договору.
      </p>
      <p>
        6.3. Заказчик обязуется: 6.3.1. Соблюдать и обеспечить соблюдение им
        всех правил получения Услуг, установленных Исполнителем данной Офертой
        или размещенные на Сайте, направленные Заказчику на электронный адрес
        почты. 6.3.2. Оплачивать в полном объеме предоставление доступа к
        информационным материалам. 6.3.3. Обеспечить конфиденциальность
        полученных от Исполнителя данных. Не распространять информационные
        материалы, полученные в ходе оказания Услуг, а также не записывать их на
        аудио- и/или видеоносители. В случае, если Заказчик допустит
        распространение указанной информации, он несет ответственность перед
        Исполнителем за причиненные фактом распространения информации убытки,
        включая недополученную прибыль, в размере указанным в п. 8.2. Договора.
        6.3.4. Обеспечить защиту доступа к своему Личному кабинету и не вправе
        передавать права по настоящему Договору третьим лицам без письменного
        согласия Исполнителя. При установлении Заказчиком фактов
        несанкционированного доступа к своей учетной записи, он обязуется в
        кратчайшие сроки уведомить об этом обстоятельстве Исполнителя по почте
        support@ku-pomogu.ru
      </p>
      <p>
        6.4. Заказчик имеет право: 6.4.1. На получение услуг от Исполнителя на
        условиях настоящего Договора. 6.4.2. Отказаться от писем, присылаемых
        Исполнителем, перейдя по ссылке, указанной в электронном письме.
      </p>
      <h2>7. Срок действия и изменение Оферты</h2>
      <p>
        7.1. Настоящая Оферта вступает в силу с момента совершения Заказчиком
        Акцепта Оферты и действует до полного выполнения обязательств Сторонами.
      </p>
      <p>
        7.2. Заказчик соглашается и признает, что внесение изменений в Оферту
        влечет за собой внесение этих изменений в заключенный и действующий
        между Заказчиком и Исполнителем договор Оферты, и эти изменения вступают
        в силу одновременно с такими изменениями в Оферте.
      </p>
      <p>
        7.3. Продолжая использование Сайта Исполнителя после вступления в силу
        соответствующих изменений, Заказчик выражает свое согласие с условиями
        настоящего Договора в новой редакции.
      </p>
      <h2>8. Ответственность сторон и заключительные положения</h2>
      <p>
        8.1. За невыполнение или ненадлежащее выполнение обязательств по
        настоящей Оферте, Стороны несут ответственность, согласно действующему
        законодательству РФ.
      </p>
      <p>
        8.2. Заказчик несет ответственность согласно пункту 6.3.3. в размере 100
        000 (ста тысяч) рублей 00 копеек за каждый факт нарушения.
      </p>
      <p>
        8.3. Все споры и разногласия, которые могут возникнуть из настоящей
        Оферты или в связи с ней, должны разрешаться путем переговоров между
        Сторонами. В случае невозможности достичь соглашения путем переговоров,
        Стороны обращаются в суд по месту исполнения услуг, указанным в
        реквизитах Исполнителя.
      </p>
      <p>
        8.4. Не вступая в противоречие с указанным выше, Исполнитель
        освобождается от ответственности за нарушение условий настоящей Оферты,
        если такое нарушение вызвано действием обстоятельств непреодолимой силы
        (форс-мажор), включая: действия органов государственной власти (в т.ч.
        принятие правовых актов), пожар, наводнение, землетрясение, другие
        стихийные бедствия, отсутствие электроэнергии и/или сбои работы
        компьютерной сети, забастовки, гражданские волнения, беспорядки, любые
        иные обстоятельства, не ограничиваясь перечисленным, которые могут
        повлиять на исполнение Исполнителем настоящего соглашения.
      </p>
      <p>
        8.5. Заказчик не вправе уступать свои права по Договору третьим лицам
        без письменного разрешения Исполнителя.
      </p>
      <p>
        8.6. Любые уведомления, связанные с исполнением Договора, могут
        направляться Сторонами по электронной почте и/или по мессенджерам,
        привязанным к номеру телефона, указываемого в Заявке/Анкете. • на адрес
        электронной почты Заказчика (e-mail) и/или телефон, которые были указаны
        Заказчиком при акцепте Оферты или в сообщении об их замене, с адреса
        электронной почты Исполнителя, если получателем является Заказчик; • на
        адрес электронной почты Исполнителя info@ku-pomogu.ru с адреса
        электронной почты Заказчика, указанного им при акцепте Оферты или в
        сообщении о его замене.
      </p>
      <p>
        8.7. Оплата по данной Оферте означает согласие со всеми условиями
        (пунктами) перечисленными выше, а также подтверждение того
        обстоятельства, что Заказчик ознакомлен в полном объеме с оказываемыми
        ему услугами, их характеристиками, способом оказания и стоимостью.
      </p>
      <h2>9. Реквизиты Исполнителя</h2>
      <p>
        Расчётный счёт: 40802810620000149392
        <br />
        Название банка: ООО "Банк Точка"
        <br />
        БИК: 044525104
        <br />
        Корреспондентский счёт: 30101810745374525104
        <br />
        Наименование: Индивидуальный предприниматель Перлин Петр Алексеевич
        <br />
        ИНН: 381115172141
        <br />
      </p>
    </div>
  );
};

export default Offer;
